<template>
  <div>
    <FullscreenLoader v-if="!user" />

    <template v-else>
      <h2 class="oleo">Mijn beschrijving</h2>

      <!-- Beschrijving -->
      <div class="mijnps-pb">
        <div class="functie-buttons">
          <button class="btn" @click="$router.push('/mijn-beschrijving/edit')">
            Wijzig tekst
          </button>
          <button class="btn" @click="$router.push('/mijn-beschrijving/foto')">
            Wijzig foto
          </button>
        </div>

        <div class="mijnps-pb__foto"
        :class="{'mijnps-pb__foto--groot': fotoGroot}">
          <img
            :src="user.foto"
            alt="Profielfoto niet gevonden"
            load="lazy"
            @click="handleOpenFotos"
            style="width: 100%; height: 100%; object-fit: cover"
            
          />
          <span
            class="fototeller"
            v-if="user.aantal_extra_fotos && user.aantal_extra_fotos > 0"
          >
            nog {{ user.aantal_extra_fotos }}
          </span>
        </div>

        <div class="mijnps-pb__tekst">{{ user.persoonsbechrijving.PB }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      fotoGroot: false,
    };
  },
  mounted() {
    scrollTo(0, 0);
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setFotos"]),
    handleOpenFotos() {
      this.setFotos({
        foto: this.user.foto,
        extra_fotos: this.user.extra_fotos || null,
      });
    },
  },
};
</script>